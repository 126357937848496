import React from "react";
import Cookies from "js-cookie";
import FidoLoader from "@vp/fido-loader";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { isLocalHost, isStaging, isBranch } from "@shared/utils/Debug";
import { getPricingContextModule } from "@shared/utils/Pricing";
import { useAppSelector } from "@shared/redux";
import abTestCookie from "@vp/ab-test-cookie";

// prevents console errors when running in these environments
// we may add sw to these later for improved testing, so want to call each out specifically
const shouldUseFido = () => !(isLocalHost() || isStaging() || isBranch());

function FidoLoaderWrapper() {
    const mpvId = useAppSelector(state => state.mpvId);
    const locale = useAppSelector(state => state.locale);
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const selectedProductOptions = useAppSelector(state => state.customerSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);
    const document = useAppSelector(state => state.document);
    const pricingInitialized = useAppSelector(state => state.pricingInitialized);
    const nextStep = useAppSelector(state => state.nextStepUrl);
    const predictedSteps = useAppSelector(state => state.predictedSteps);
    const { auth, identity } = useIdentityContext();
    const isReady = !!nextStep;
    if (!shouldUseFido() || !isReady) {
        return null;
    }

    const documentRevisionUrl = document?.documentRevisionUrl;

    const pcxtv3 = pricingInitialized ? getPricingContextModule() : undefined;

    // pricingContextText is the base64 encoded pricing context string needed for the web worker
    const pricingContextText = pcxtv3 ? pcxtv3.getEncodedContextString() : "";
    // pricingContextQSP is the qsp parameters needed for wrangler
    const pricingContextQSP = pcxtv3 ? pcxtv3.getContextAsQueryString() : "";
    const sciscxCookie = Cookies.get("sci-scx");

    let userTestId;
    try {
        userTestId = abTestCookie?.ensureUserTestId();
    } catch (e) {
        // NOOP
    }

    return (
        <FidoLoader
            isReady={isReady}
            mpvId={mpvId}
            locale={locale}
            country={locale ? locale.split("-")[1] : ""}
            tenant={MERCHANDISING_TENANT}
            productKey={productKey}
            productVersion={productVersion}
            selectedOptions={selectedProductOptions}
            quantity={quantity}
            nextStep={nextStep?.url}
            documentUrl={documentRevisionUrl}
            documentRevisionUrl={documentRevisionUrl}
            pricingContext={pricingContextText}
            predictedSteps={predictedSteps}
            siteContext={sciscxCookie || ""}
            userTestId={userTestId}
            customerId={identity?.shopperId || identity?.anonymousUserId}
            accessToken={auth?.getToken()}
            pricingContextString={pricingContextQSP}
        />
    );
}

FidoLoaderWrapper.displayName = "FidoLoaderWrapper";

export default FidoLoaderWrapper;
